<template>
  <div>
    <div id="add-player">
      <h2 style="margin: calc(0.83em - 4px) 0 4px 0;">Benutzers einladen</h2>
      <PlayerSearch
          autocomplete="off"
          type="text"
          placeholder="Spieler*in zum Hinzufügen auswählen"
          v-model="playerToAdd"
          :user-has-account="true" />
      <div id="add-player-btn-wrapper">
        <button class="btn" @click="generateToken()">Zugangslink generieren</button>
      </div>
      <div v-if="token" id="token-url-wrapper">
        <input id="token-url" :disabled="true" :value="`${baseUrl}${token}`"/>
      </div>
    </div>
    <div id="toggle-table-highlight-box">
      <label for="toggle-table-highlight">
        <input type="checkbox" id="toggle-table-highlight" v-model="toggleTableHighlight" @change="updateLocalStorage()" :key="toggleTableHighlight"/>
        {{ toggleTableHighlight ? "WIN/LOSE Hintergrund ist aktiv" : "WIN/LOSE Hintergrund ist deaktiviert" }}
      </label>
    </div>
    <div>
      <div>14 Spiele gezockt</div>
      <div>davon 7 gewonnen</div>
    </div>
    <div>
      <div>27 mal RE</div>
      <div>das sind 50%</div>
    </div>
    <div>
      <div>8 mal Schweine</div>
      <div>das sind 20%</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PlayerSearch from "@/modules/PlayerSearch.vue";

export default {
// eslint-disable-next-line
  name: "Profile",
  components: {PlayerSearch},
  data(){
    return {
      playerToAdd: null,
      toggleTableHighlight: undefined,
      token: null,
      loading: false,
      user_info: {
        name: "admin"
      }
    }
  },
  created() {
    console.log("localStorage.getItem('doko-tracker-toggleTableHighlight')", localStorage.getItem('doko-tracker-toggleTableHighlight'));
    this.toggleTableHighlight = localStorage.getItem('doko-tracker-toggleTableHighlight');

    this.baseUrl = location.origin + "/create_user/";
    this.loading = true;

    axios.get(`${this.$hostname}/get_player_info`).then(result => {
      console.log("get_player_info", result.data);
      this.user_info.name = result.data.name;
      localStorage.setItem('doko-tracker-username', this.user_info.name);
      this.user_info.lastTwentyPoints = result.data.historie;
      console.info(result);
    }).finally(() => {
      this.loading = false;
    });
  },
  methods: {
    generateToken(){
      console.log(location);
      console.log(this.playerToAdd);
      if(!this.playerToAdd){
        alert("kein User ausgewählt");
        return;
      }
      axios.get(`${this.$hostname}/get_token?user_id=${this.playerToAdd.user_id}`).then(result => {
        //this.loading = false;
        this.token = result.data;
      });
    },
    updateLocalStorage(){
      console.log("set localStorage.setItem('doko-tracker-toggleTableHighlight' to ", this.toggleTableHighlight)
      localStorage.setItem('doko-tracker-toggleTableHighlight', this.toggleTableHighlight);
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/css/colors.scss";
#add-player{
  padding: 6px 8px;
  border-radius: 6px;
  box-shadow: 0 0 2px $mainColor;
  background: $background;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
#add-player-btn-wrapper{
  display: flex;
}
#add-player-btn-wrapper > button{
  flex: 1;
}

#token-url-wrapper{
  overflow-x: auto;
  margin: 6px 8px;
}
#token-url{
  background: darken($background, 20%);
  border-radius: 2px;
  padding: 4px 6px;
  width: 100%;
}
</style>