<template>
  <div id="scrollbar">
    <div id="root-element">
      <div id="welcome-box">
          <h1>Hallo {{user_info.name}}</h1>
          <p id="welcome_text">Was möchtest du machen?</p>
      </div>


      <router-link to="/game" tag="button" id="new-game-btn">Neues Spiel starten!</router-link>

      <router-link to="/history" tag="button" id="history-btn">
        <PlayerHistoryList :only-fresh-games="true" :limit="2"/>
        <span id="history-btn-text">gespielte Games</span>
      </router-link>

      <router-link to="/profile" tag="button" id="profile-btn">
        Dein<br>Profil
      </router-link>

      <router-link to="/stats" tag="button" id="stats-btn">
          <PlayerHistoryUI :pointHistory="user_info.lastTwentyPoints"/>
          Statistik
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PlayerHistoryUI from "@/modules/PlayerHistoryUI.vue";
import PlayerHistoryList from "@/modules/PlayerHistoryList.vue";

export default {
  // eslint-disable-next-line
  name: "User",
  components: {PlayerHistoryList, PlayerHistoryUI},
  data(){
    return {
      playerToAdd: null,
      token: null,
      baseUrl: null,
      loading: false,
      user_info: {
          name: "admin",
          lastTwentyPoints: Array.from({length: 20}, () => Math.floor(Math.random() * 200 - 100))
      }
    }
  },
  created() {
    this.baseUrl = location.origin + "/create_user/";
    this.loading = true;

    axios.get(`${this.$hostname}/get_player_info`).then(result => {
      console.log("get_player_info", result.data);
      this.user_info.name = result.data.name;
      localStorage.setItem('doko-tracker-username', this.user_info.name);
      this.user_info.lastTwentyPoints = result.data.historie;
      console.info(result);
    }).finally(() => {
      this.loading = false;
    });
  },
}
</script>

<style lang="scss">
@import "@/css/colors.scss";
#scrollbar{
  height: calc(100% - (28px + 1.2em));
  overflow-x: hidden;
  overflow-y: auto;
  background: mix($background, $mainColor, 92%) !important;
}
#root-element{
  background: none !important;
  box-shadow: none !important;
  min-height: 100%;
  display: grid;
  align-items: stretch;
  gap: 12px;
  width: calc(100% - 84px) !important;
  margin: 0 42px !important;
  padding-bottom: 32px;
}
#welcome_text{
  text-align: center;
}
#new-game-btn, #stats-btn, #profile-btn, #history-btn{
  aspect-ratio: 8 / 5; // golden ratio
}
@media (min-width:641px) and (max-width: 960px){
  #root-element {
    grid-template-columns: repeat(2, 50%);
  }
  #welcome-box{
    grid-column: 1 / 3;
    grid-row: 1 / 1;
  }
  #stats-btn{
    grid-column: 1 / 3;
    grid-row: 2 / 2;
    max-height: 280px;
    width: 100%;
  }
  #history-btn{
    grid-column: 1 / 3;
    grid-row: 4;
  }
}
@media (min-width:961px) and (max-width: 1280px){
  #root-element{
    grid-template-columns: repeat(3, 33.333%);
    grid-template-areas:
    "welcome-box welcome-box welcome-box"
    "stats stats ."
    "stats stats ."
    ". . .";
    width: calc(100% - 84px) !important;
    margin: 0 42px !important;
  }

  #welcome-box{
    grid-area: welcome-box;
  }
  #profile-btn{
    grid-column: 3/3;
    grid-row: 3/3;
  }
  #stats-btn{
    grid-area: stats;
  }
  #new-game-btn{
    grid-column: 3/3;
    grid-row: 2/2;
  }
  #history-btn {
    grid-column: 1 / 4;
    grid-row: 4 / 4;
  }
}
@media (min-width:1281px) {
  #root-element{
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 120px auto auto;
    align-items: stretch;
  }
  #welcome-box{
    grid-column: 1 / 3;
    grid-row: 1 / 1;
  }
  #profile-btn,
  #stats-btn,
  #new-game-btn,
  #history-btn {
    max-width: 420px;
  }
  #profile-btn,
  #new-game-btn{
    margin-left: auto;
  }
}

#new-game-btn {
  color: $accentColor;
  background: $secondColor;
  border: 1px solid $secondColorDark;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 1.8em;
  font-weight: bold;
}
#new-game-btn:hover{
  background: linear-gradient(98deg, $secondColor 0%, $secondColorDark 32%, $secondColor 100%);
  color: $accentColorDark;
  border: 4px solid $accentColorDark;
}

#history-btn{
  border-radius: 6px;
  background: $mainColorDark;
  color: $oneMoreColor;
}
#history-btn:hover{
  background: linear-gradient(57deg, $mainColor 0%, $mainColorDark 32%, $mainColorDark 100%);
  border: 4px solid $oneMoreColor;
}

.game_overview {
  max-width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.game_overview:hover{
  background: $oneMoreColor;
}
#history-btn-text {
  font-size: 1.8em;
  font-weight: bold;
}



#stats-btn {
  background: gold;
  background: linear-gradient(145deg, gold 0%, #edbb23 47%, #fdbb2d 100%);
  text-shadow: 0 0 2px goldenrod;
  border: 1px solid gold;
  color: $secondColorDark;
  box-shadow: 0 1px 6px -3px black;
  transition: all 0.32s cubic-bezier(0.66, 0.13, 0.4, 0.99);
  border-radius: 6px;
  font-size: 1.8em;
  font-weight: bold;
  padding: 8px;
}
#stats-btn:hover{
  background: linear-gradient(145deg, #edbb23 0%, gold 47%, #e7aa28 100%);
  color: $secondColorDark;
  box-shadow: 0 2px 7px -2px $secondColorDark;
  border: 4px solid $secondColor;
}


#profile-btn{
  padding: 6px 8px;
  border-radius: 6px;
  box-shadow: inset 0 0 8px $mainColorDark;
  background: $mainColor;
  font-size: 2.2em;
  font-weight: bold;
  color: $mainColorText;
  text-shadow: 0 1px 4px $mainColorDark, 1px 1px 0 black;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  transition: all 0.32s cubic-bezier(0.66, 0.13, 0.4, 0.99);
}
#profile-btn:hover{
  box-shadow: inset 0 0 2vmin $mainColorDark, 0 1px 6px -3px black;
  border: 4px solid $mainColorDark;
  text-shadow: 0 1px 7px $mainColorDark, 2px 2px 0 black;
}
h2{
  text-align: center;
}
</style>