<template>
  <div id="app">
    <div v-if="cheatSheetisOpen" id="cheatsheet-background" @click="cheatSheetisOpen = false"></div>
    <div v-if="cheatSheetisOpen" id="cheatsheet">
        <div class="cheatSheetItem" id="cheatsheet-trumpf">
          <h3 title="Reihenfolge">Trümpfe (Reihenfolge)</h3>
          <table>
            <tbody><tr><td>1.&nbsp;</td><td>Herz </td><td><span class="rd">&nbsp;♥</span> Zehn</td></tr>
            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>2.&nbsp;</td><td>Kreuz </td><td><span class="bk">&nbsp;♣</span> Dame <span class="smalla">(Alten)</span></td></tr>
            <tr><td>3.&nbsp;</td><td>Pik</td><td><span class="bk">&nbsp;♠</span>&nbsp; "</td></tr>
            <tr><td>4.&nbsp;</td><td>Herz</td><td><span class="rd">&nbsp;♥</span>&nbsp; "</td></tr>
            <tr><td>5.&nbsp;</td><td>Karo</td><td><span class="rd">&nbsp;♦</span>&nbsp; "</td></tr>

            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>6.&nbsp;</td><td>Kreuz </td><td><span class="bk">&nbsp;♣</span> Bube <span class="smalla">(Karlchen)</span></td></tr>
            <tr><td>7.&nbsp;</td><td>Pik </td><td><span class="bk">&nbsp;♠</span>&nbsp; "</td></tr>
            <tr><td>8.&nbsp;</td><td>Herz </td><td><span class="rd">&nbsp;♥</span>&nbsp; "</td></tr>
            <tr><td>9.&nbsp;</td><td>Karo </td><td><span class="rd">&nbsp;♦</span>&nbsp; "</td></tr>

            <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td>10.&nbsp;</td><td>Karo </td><td><span class="rd">&nbsp;♦</span> As <span class="smalla">(Fuchs)</span></td></tr>
            <tr><td>11.&nbsp;</td><td>&nbsp; "</td><td><span class="rd">&nbsp;♦</span> Zehn</td></tr>
            <tr><td>12.&nbsp;</td><td>&nbsp; "</td><td><span class="rd">&nbsp;♦</span> König </td></tr>
            <tr><td>13.&nbsp;</td><td>&nbsp; "</td><td><span class="rd">&nbsp;♦</span> Neun <span class="smalla">[optional]</span></td></tr>
            </tbody></table>
          </div>
        <div class="cheatSheetItem" id="cheatsheet-fehl">
          <h3 title="Reihenfolge">Fehlfarben</h3>
          <p>As - Zehn - König - Neun <span class="smalla">[optional]</span></p>
        </div>

        <div class="cheatSheetItem" id="cheatsheet-kartenwert">
          <h3 title="Doppelkopf">Kartenwert (Augen)</h3>

          <table>
            <tbody><tr><td style="width:20%;">As &nbsp;</td><td style="width:8%; text-align:right;"> 11</td><td style="width:24%;">&nbsp;</td><td style="width:20%;">Zehn &nbsp;</td><td style="width:8%; text-align:right;"> 10</td></tr>
            <tr><td>König &nbsp;</td><td style="text-align:right;">4</td><td>&nbsp;</td><td>Dame &nbsp;</td><td style="text-align:right;"> 3</td></tr>
            <tr><td>Bube &nbsp;</td><td style="text-align:right;">2</td><td>&nbsp;</td><td>Neun &nbsp;</td><td style="text-align:right;"> 0</td></tr>

            </tbody></table>
        </div>

        <div class="cheatSheetItem" id="cheatsheet-spielpunkte">
          <h3 title="Re und Kontra">Spielpunkte</h3>

          <ul>
            <li>1 Punkt für jeweils <strong>keine </strong> 120, 90, 60, 30, 0</li>
            <li>+ 1 Punkt je <strong>angesagte keine </strong> 120, 90, ...</li>
            <li>+/- 1 Sonderpunkt pro <strong>Extra</strong></li>
            <li>+ <strong>Re/Kontra</strong>: Punkte werden verdoppelt</li>

          </ul>
        </div>

        <div class="cheatSheetItem" id="cheatsheet-extras">
          <h3 title="Karlchen, Doppelkopf, Fuchs, Solo">Extras</h3>

          <ul>
            <li><strong>Karlchen:</strong> Kreuz Bube macht den letzten Stich</li>
            <li><strong>Karlchen fangen:</strong> Kreuz Bube wird im letzten Stich von Karo Dame gefangen</li>
            <li><strong>Doppelkopf:</strong> Ein Stich mit 40 oder mehr Augen</li>
            <li><strong>Solo:</strong> Damen, Buben, Trumpf (Farb); Hochzeit</li>
            <li><strong>Fuchs </strong> (Karo As) der Gegenpartei gefangen</li>
            <li><strong>Gegen die Alten</strong> (Kreuz Damen) gewonnen</li>

          </ul>
        </div>
        <div class="cheatSheetItem" id="cheatsheet-source">
          <h3>Kopiert von</h3>
          <a href="https://www.spielanleitung.org/doppelkopf.html" target="_blank">www.spielanleitung.org/doppelkopf.html</a>
        </div>
    </div>
    <div id="header">
      <span id="user-profile"></span>
      <router-link :is="allowedPages ? 'span' : 'router-link'" to="/me" tag="span" id="logo">Online DoppelKopf Tracker</router-link>
      <span id="doko-help" @click="cheatSheetisOpen = true">Cheat<br/>sheet</span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'App',
  computed: {
    allowedPages(){
      return !!(this.$route.name === "Login" || this.$route.name === "CreateUser");
    }
  },
  data(){
    return {
      cheatSheetisOpen: false,
      username: localStorage.getItem('doko-tracker-username') || ""
    }
  },
  created() {
    if(!localStorage.getItem("access_token") && !this.allowedPages){
      console.error(localStorage.getItem("access_token"), this.$route.name);
      this.$router.replace({
        path: "/login",
        query: { redirect: this.$router.currentRoute.fullPath }
      });
    }else{
      // check if user is for real logged in.
      // If session expired, user will be kicked out
      axios.get(this.$hostname);
    }
  }
}
</script>

<style lang="scss">
@import "@/css/colors.scss";

* {
  box-sizing: border-box;
}
body, html{
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondColor;
  background: mix($background, $mainColor, 92%);
}
#header{
  background: $mainColor;
  padding: 14px;
  text-align: center;
  color: $mainColorText;
  text-shadow: 1px 1px 2px $secondColorDark;
  border-bottom: 1px solid $mainColorDark;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}
#logo{
  font-size: 1.2em;
}
#user-profile{
  text-align: left;
}
#doko-help{
  text-align: right;
  font-size: 0.8em;
}
#user-profile, #doko-help {
  flex: 1;
  text-decoration: underline dotted 1px black;
  cursor: pointer;
}
#doko-help:hover {
  text-decoration-style: wavy;
}
h1, h2, h3, h4, h5, h5{
  text-align: center;
}



button {
  border: 0;
  background: $background;
  padding: 6px 8px;
  cursor: pointer;
}
.btn {
  display: flex;
  justify-content: center;
  background: $secondColor;
  color: $secondColorText;
  margin: 5px;
  padding: 6px 8px;
  border-radius: 2px;
  border: 1px solid $secondColorDark;
  cursor: pointer;
}

label {
  text-align: center;
  padding: 6px 8px;
}
label > input[type=radio]{
/*  display: none; */
}

/* https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */
#app > *:not(#header){
  width: 100%;
  background: $background;
  overflow-y: auto;
}
/* @media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones / }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. / } */
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  #app > *:not(#header, #scrollbar), #root-element{
    margin: 0 42px;
    width: calc(100% - 84px);
    box-shadow: 0 4px 8px rgba($mainColorDark, .5);
  }
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
  #app > *:not(#header, #scrollbar), #root-element{
    margin: 0 19vw;
    width: 62vw;
  }
}
/*
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops / }*/
@media (min-width:1281px) { /* hi-res laptops and desktops */
  #app > *:not(#header, #scrollbar), #root-element{
    margin: 0 auto;
    width: 960px;
  }
}


#cheatsheet-background{
  margin: 0 !important;
}
#cheatsheet{
  margin: 42px 0 0 0 !important;
}
#cheatsheet-background {
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  background: repeating-linear-gradient(45deg, white, white 10px, rgba(0, 0, 0, 0.3) 10px, rgba(0, 0, 0, 0.3) 20px) !important;
  z-index: 2 !important;
}
#cheatsheet{
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 6px;
  background: $background;
  border-radius: 8px;
  z-index: 3 !important;
  display: grid;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  overflow-y: scroll;
  max-width: 92vw;
  max-height: 90vh;
  box-shadow: 0 0 8px $secondColorDark !important;
}

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  #cheatsheet{
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
  }
  #cheatsheet-trumpf { grid-area: 1 / 1 / 3 / 2; }
  #cheatsheet-fehl { grid-area: 3 / 1 / 4 / 2; }
  #cheatsheet-kartenwert { grid-area: 1 / 2 / 2 / 3; }
  #cheatsheet-spielpunkte{ grid-area: 2 / 2 / 3 / 3; }
  #cheatsheet-extras { grid-area: 3 / 2 / 4 / 3; }
}
#cheatsheet > span.rd {
  color: red;
}
#cheatsheet .smalla {
  font-size: 0.7em;
  font-weight: bold;
}
#cheatsheet-trumpf, #cheatsheet-fehl, #cheatsheet-kartenwert, #cheatsheet-spielpunkte, #cheatsheet-extras {
  border: 4px solid;
  border-radius: 4px;
}
#cheatsheet-trumpf {
  border-color: $oneMoreColor;
}
#cheatsheet-trumpf h3, #cheatsheet-trumpf .smalla {
  color: $oneMoreColor;
}
#cheatsheet-fehl {
  border-color: $mainColorDark;
}
#cheatsheet-fehl h3, #cheatsheet-fehl .smalla  {
  color: $mainColorDark;
}
#cheatsheet-fehl > p {
  text-align: center;
}
#cheatsheet-kartenwert {
  border-color: $accentColorDark;
}
#cheatsheet-kartenwert h3 {
  color: $accentColorDark;
}
#cheatsheet-spielpunkte{
  border-color: $secondColorDark;
}
#cheatsheet-spielpunkte h3 {
  color: $secondColorDark;
}
#cheatsheet-extras {
  border-color: $dangerColorDark;
}
#cheatsheet-extras h3 {
  color: $dangerColorDark;
}
</style>
