<template>
  <div id="history">
    <h2>gespielte Games</h2>
    <Loading v-if="this.loading" :player-name="this.randomName"/>
    <PlayerHistoryList @loading="(l) => this.loading = l" @no-games-found="this.noGameFound = true"/>
    <div v-if="this.noGameFound" id="no-games-yet-interaction">
      Starte mit einem
      <router-link to="/game" tag="a">Klick</router-link>
      dein erstes Spiel!
    </div>
  </div>
</template>

<script>
import Loading from "@/modules/Loading.vue";
import PlayerHistoryList from "@/modules/PlayerHistoryList.vue";

export default {
  // eslint-disable-next-line
  name: "PlayerHistory",
  components: {PlayerHistoryList, Loading},
  data(){
    return {
      randomName: localStorage.getItem('doko-tracker-username') || null,
      loading: true,
      noGameFound: false,
    }
  },
}
</script>
<style scoped lang="scss">
h2{
  margin-top: 42px;
  margin-bottom: 12px;
}
#history{
  overflow-y: auto;
}
</style>