var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main"}},[_c('div',{style:({
                        'height': ((Math.max.apply(Math, _vm.pointHistory)) + "px")
                 }),attrs:{"id":"upper"}},_vm._l((_vm.pointHistory),function(point,index){return _c('div',{key:index},[(point > 0)?_c('div',{staticClass:"upper-points",style:({
                        'height': ((_vm.heights[index]) + "%")
                 })}):_c('div',{staticClass:"empty-space"})])}),0),_c('div',{attrs:{"id":"zero"}}),_c('div',{style:({
                         'height': ((Math.abs(Math.min.apply(Math, _vm.pointHistory))) + "px")
                 }),attrs:{"id":"downer"}},_vm._l((_vm.pointHistory),function(point,index){return _c('div',{key:index},[(point < 0)?_c('div',{staticClass:"downer-points",style:({
                        'height': ((_vm.heights[index]) + "%")
                 })}):_c('div',{staticClass:"empty-space"})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }