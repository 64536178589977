<template>
  <Loading v-if="loading"/>
  <div id="game" v-else-if="gameFound">
    <div id="konsum">
      <!-- <button id="konsum-btn" @click="toggleKonsumModal()">{{isKonsumView ? "Zurück zum Spiel" : "Konsum eintragen"}}</button> -->
      <label for="toggle-table-highlight">
        <input type="checkbox" id="toggle-table-highlight" v-model="toggleTableHighlight"/>
        {{ toggleTableHighlight ? "WIN/LOSE Hintergrund ist aktiv" : "WIN/LOSE Hintergrund ist deaktiviert" }}
      </label>
    </div>
    <div v-if="isKonsumView" id="konsum-overview">
      <div class="konsum-element">1 Bier</div>
      <div class="konsum-element">1 Wein</div>
      <div class="konsum-element">1 Joint</div>
    </div>
    <table id="overview">
        <thead id="overview-header">
        <tr>
          <th v-for="player in spieler" :key="player.id">
            <button v-if="isKonsumView" class="player-konsum">
              +
            </button>
            <div class="player-wrapper" :class="getHeaderInfo(player).class">
              <span class="player-name">{{ player.name }}</span>
              <span class="player-info" v-if="getHeaderInfo(player).text">{{ getHeaderInfo(player).text }}</span>
            </div>
          </th>
          <th v-if="!isKonsumView">
            <div id="punkte-wrapper">
              <span id="punkte-header">P</span>
            </div>
          </th>
        </tr>
        </thead>
        <tbody id="overview-punkte" v-if="!isKonsumView">
          <tr v-for="(runde, index) in runden" :key="runde.id" :class="{row: true, divider: index % spieler.length === spieler.length - 1}">
            <td
                v-for="(player, index) in pointsPerRound(runde)"
                :key="player.id + '.' + index + '.' + runde.punkte"
                :class="{ 'player-points': 1, 'positive' : player.punkte > 0 && toggleTableHighlight, 'negative': player.punkte < 0 && toggleTableHighlight }"
            >
              <div >{{ player.zwischenstand }} <span v-if="player.solo" class="solo-badge">S</span></div>
            </td>
            <td class="punkte-per-round">
              <div class="runden-points">{{ runde.punkte }} <ShowBock :bock-count="runde.bock"/></div>
            </td>
          </tr>
          <template v-if="!gesperrt">
            <tr v-for="(bockCount, index) in remainingBock" :key="index" :class="{row: true, divider: (runden.length + index) % spieler.length === spieler.length - 1}">
              <td :colspan="spieler.length">
                <div class="player-points">&nbsp;</div>
              </td>
              <td>
                <ShowBock :bock-count="bockCount" class="runden-points"/>
              </td>
            </tr>
            <tr id="footer-row">
              <td :colspan="spieler.length + 1"><button @click="undoLastRound()" v-if="runden.length > 0">letzte Runde löschen</button></td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(bockCount, index) in remainingBock" :key="index" :class="{row: true, divider: (runden.length + index) % spieler.length === spieler.length - 1}">
              <td v-for="player in spieler" :key="player.id">
                <div class="player-points">&nbsp;</div>
              </td>
              <td>
                Hochi
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    <div id="interaction-wrapper" v-if="!gesperrt">
      <div id="complicated-btn">
        <button class="interaction-w-confirmation" @click="areYouSure = !areYouSure">
          {{areYouSure ? 'abbrechen' : 'beenden'}}
        </button>
        <button class="are-you-sure-interaction" v-if="areYouSure" @click="lockGame()">Spiel beenden!</button>
        <router-link :to="`/game/${gameID}/entry`" tag="button" id="enter-results-btn" v-else>Ergebnisse eintragen</router-link>
      </div>
    </div>
    <div v-else>
      <img id="graph" :src="graphUrl" />
    </div>
  </div>
  <div v-else id="game-not-found">
    <span v-if="gameID">Kein Spiel unter <i>{{gameID}}</i> gefunden.</span>
    <span v-else>Ohne gameID wirds schwierig, ein Spiel zu finden.</span>
    <router-link to="/">Neues Spiel anlegen</router-link>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/modules/Loading";
import ShowBock from "@/modules/ShowBock";
export default {
  // eslint-disable-next-line
  name: "Game",
  components: {ShowBock, Loading},
  computed: {
    gameID () {
      return this.$route.params.id
    }
  },
  created() {
    console.log("this.gameID", this.$route.params.id);
    if(!this.gameID){
      console.log("no gameID, no luck")
      this.loading = false;
      this.gameFound = false;
      return;
    }
    this.continousUpdate();
  },
  data(){
    return {
      loading: true,
      spieler: [],
      runden: [],
      remainingBock: [],
      gesperrt: false,
      graphUrl: "",
      isKonsumView: false,
      areYouSure: false,
      gameFound: false,
      toggleTableHighlight: false,
    }
  },
  methods: {
    continousUpdate(){
      axios.get(`${this.$hostname}/game/${this.gameID}`).then(result => {
        this.gameFound = true;
        this.gesperrt = result.data.gesperrt;
        if(this.gesperrt){
          this.loadGraph()
        }
        this.spieler = result.data.spieler;
        this.runden = result.data.runden;
        this.remainingBock = result.data.remainingBock;
      }).catch(error => {
        console.error(error);
        if(this.loading){
          this.gameFound = false;
        }
      }).finally(() => {
        this.loading = false;
        // Schedule the next update
        if(!this.gesperrt){
          setTimeout(this.continousUpdate, 10000); // 10 seconds
        }
      });
    },
    pointsPerRound(runde){
      let result = [];
      this.spieler.forEach(spieler => {
        const player = runde.spielerArray.find(player => player.id === spieler.id);
        if(player){
          console.log(player, player.zwischenstand);
          result.push({...player});
        }else{
          result.push({zwischenstand: "-/-"});
        }
      });
      console.log(result);
      return result;
    },
    toggleKonsumModal(){
      this.isKonsumView = !this.isKonsumView;
    },
    getHeaderInfo(player){
      if(player.kommt_raus){
        return {class: "kommt_raus", text: "kommt\nraus"};
      }
      if(player.aussetzen){
        return {class: "aussetzen", text: "setzt\naus"};
      }
      return {class: null, text: null};
    },
    lockGame(){
      this.loading = true;
      axios.post(`${this.$hostname}/game/${this.gameID}/lock`).then(result => {
        this.loading = false;
        this.gesperrt = true;
        console.log(result);
      }).catch(error => {
        console.error(error);
        this.loading = false;
      });
    },
    undoLastRound(){
      if(confirm("Letzte Runde wirklich löschen?")){
        axios.delete(`${this.$hostname}/game/${this.gameID}`).then(result => {
          this.spieler = result.data.spieler;
          this.runden = result.data.runden;
          this.remainingBock = result.data.remainingBock;
        });
      }
    },
    loadGraph(){
      axios.get(`${this.$hostname}/result_plot/${this.gameID}`).then(result => {
        // super hacky, server is just returning a String of a BASE64 encoded png image
        this.graphUrl = result.data;
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/css/colors.scss";

#konsum{
  display: flex;
  background: $dangerColorDark;
}
#konsum-btn{
  flex: 1;
  background: $background;
  color: $secondColor;
  box-shadow: 0 1px 2px $secondColorDark;
  margin: 5px;
  border-radius: 2px;
  border: 1px solid $secondColorDark;
  font-size: 1.2em;
  box-sizing: border-box;
}
.konsum-element{
  margin: 12px;
  padding: 6px;
  border: 5px solid $background;
  background: $secondColor;
  font-size: 1.6rem;
  font-weight: bold;
  color: $secondColorText;
}
.konsum-element:hover{
  border-color: $dangerColorDark;
  background: $secondColorDark;
  color: $dangerColor;
}

.player-konsum{
  margin: 4px 6px;
  text-align: center;
}

#game{
  height: 100%;
  max-height: 100vh;
  position: relative;
}
/** Table **/
table#overview {
  width: 100%;
  border-collapse: collapse;
}
table#overview td, table#overview th {
  padding: 0;
  margin: 0;
}
table#overview th {
  position: sticky;
  top: 0;
}
.player-wrapper, #punkte-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  text-align: center;
  background: $secondColor;
  border-bottom: 1px solid $secondColorDark;
  height: 60px;
}
#punkte-wrapper{
  background: $background;
  color: $secondColorDark;
  margin-left: -1px;
  border-left: 1px solid black;
  font-size: 1.2em;
}

.player-wrapper.kommt_raus{
  background: $accentColor;
  border-bottom: 1px solid $accentColorDark;
  font-size: 0.8em;
}

.player-wrapper.kommt_raus > .player-name{
  color: $accentColorText;
}
.player-wrapper.aussetzen {
  background: $dangerColor;
  border-bottom: 1px solid $dangerColorDark;
  font-size: 0.8em;
}
.player-wrapper.aussetzen > .player-name{
  color: $dangerColorText;
}
.player-info {
  line-height: 0.8em;
  margin-bottom: -6px;
}

.player-name{
  font-size: 1.2rem;
  color: $secondColorText;
}
.player-points, .runden-points{
  padding: 12px 8px;
  text-align: center;
}
.player-points{
  border-right: 1px solid $secondColor;
}
.player-points.positive {
  background-color: mix($background, $mainColor, 82%);
}
.player-points.negative {
  background-color: mix($background, $dangerColorDark, 82%);
}
tr.row.divider {
  border-bottom: 1px solid $secondColor;
}
#footer-row{
  height: 96px;
  vertical-align: top;
}



/** Interaction **/
#interaction-wrapper{
  position: fixed;
  bottom: 0;
  width: inherit; /* same as parent */
  background: linear-gradient(0deg, rgba(255,255,255,0.96) 0%, rgba(255,255,255,0) 69%);
  display: flex;
}
#complicated-btn{
  flex: 1;
  display: flex;
  background: $secondColor;
  color: $secondColorText;
  margin: 8px;
  border-radius: 2px;
  border: 1px solid $secondColorDark;
}
#enter-results-btn, .interaction-w-confirmation, .are-you-sure-interaction{
  background: transparent;
  color: $secondColorText;
  font-size: 1.2em;
  padding: 10px 12px;
}
#enter-results-btn, .are-you-sure-interaction{
  border-left: 1px solid $secondColorText;
  flex: 1;
}
.are-you-sure-interaction{
  background: $dangerColorDark;
  color: $dangerColorText;
}

#game-not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 8vmin;
}

#graph{
  max-width: 100%;
}
</style>